export const CONTACT = {
  PHONE: {
    icon: "fas:phone",
    link: "tel:06.31.57.07.59",
    text: "06.31.57.07.59",
    img: "header_phone.png"
  },
  MAIL: {
    icon: "far:envelope",
    link: "mailto:laetitia@littlecocon.fr",
    text: "laetitia@littlecocon.fr",
    img: "header_email.png"
  },
  FACEBOOK: {
    icon: "fab:facebook",
    link: "https://www.facebook.com/littlecocon.love"
  },
  INSTAGRAM: {
    icon: "fab:instagram",
    link: "https://www.instagram.com/littlecocon.love/"
  }
};
